import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    Create,
    SimpleForm,
    TextInput,
    Edit,
    Pagination,
    ArrayInput,
    SimpleFormIterator,
    SimpleShowLayout
} from 'react-admin';
import { Typography } from '@mui/material';
import { Box } from '@mui/material';

const ListPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 200]} {...props} />;

const TypeTitle = () => {
    //const record = useRecordContext();
    return <span>Liste des types de tâches</span>;
};

const TypeListActionToolbar = ({ children, ...props }) => (
    <Box sx={{ alignItems: 'center', display: 'flex' }}>{children}</Box>
);

/*
 * 
 /////////////////////////////////////////////////////////////////////
 ///////////////////////////////////////////////////// LIST //////////
 */
const TypePanel = () => {
    //const record = useRecordContext();
    return (
        <SimpleShowLayout>
            <TextField label="Critère(s) type dans l'objet" source="mtype" />
            <hr />
            <TextField label="Critère(s) type dans le contenu" source="mcontenu" />
        </SimpleShowLayout>
    );
};

export const TypeList = () => {
    return (
        <List 
            title={<TypeTitle />} 
            pagination={<ListPagination />}
        >
                <Datagrid
                    rowClick="expand"
                    size="small"
                    expand={<TypePanel />}
                >
                    <TextField source="id" />
                    <TextField source="label" label="Label" />
                    {/* <TextField source="type" label="Critère type dans l'objet" />
                    <TextField source="contenu"label="Critère type dans le contenu" /> */}
                    <TypeListActionToolbar>
                        <EditButton />
                    </TypeListActionToolbar>
                </Datagrid>
        </List>
    );
}


/*
 * 
 /////////////////////////////////////////////////////////////////////
 ///////////////////////////////////////////////////// EDIT //////////
 */

export const TypeEdit = () => (
    <Edit title="Editer un type" >
      <SimpleForm>
        <TextInput disabled label="Id" source="id" />
        <TextInput source="label" label="Label" fullWidth />
        {/* <TextInput source="type" label="Critère type dans l'objet" fullWidth />
        <TextInput source="contenu"label="Critère type dans le contenu" fullWidth /> */}

        <ArrayInput 
            label="Critère(s) multiple type dans l'objet" 
            source="mtype"
        >
            <SimpleFormIterator>
                <TextInput label="Critère" source="critere" fullWidth />
                <Typography variant="h7" gutterBottom>
                    <strong>ou</strong>
                </Typography>
            </SimpleFormIterator>
        </ArrayInput>

        <Typography variant="h6" gutterBottom>
            <strong>OU</strong> dans le contenu du mail
        </Typography>

        <ArrayInput 
            label="Critère(s) multiple type dans le contenu" 
            source="mcontenu"
        >
            <SimpleFormIterator>
                <TextInput label="Critère" source="critere" fullWidth />
                <Typography variant="h7" gutterBottom>
                    <strong>ou</strong>
                </Typography>
            </SimpleFormIterator>
        </ArrayInput>

      </SimpleForm>
    </Edit>
);

/*
 * 
 /////////////////////////////////////////////////////////////////////
 ///////////////////////////////////////////////////// CREATE ////////
 */
export const TypeCreate = props => (
    <Create title="Créer un type" {...props}>
      <SimpleForm>
        <TextInput source="label" label="Label" fullWidth />
        {/* <TextInput source="type" label="Critère type dans l'objet" fullWidth />
        <TextInput source="contenu"label="Critère type dans le contenu" fullWidth /> */}
        <ArrayInput 
            label="Critère(s) multiple type dans l'objet" 
            source="mtype"
        >
            <SimpleFormIterator>
                <TextInput label="Critère" source="critere" fullWidth />
            </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput 
            label="Critère(s) multiple type dans le contenu" 
            source="mcontenu"
        >
            <SimpleFormIterator>
                <TextInput label="Critère" source="critere" fullWidth />
            </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
);