// in src/posts.js
import * as React from "react";
//import { useMediaQuery } from '@mui/material';
//import { List, Datagrid, TextField, ReferenceField } from 'react-admin';
//import { List, Datagrid, TextField, ReferenceField, EditButton } from 'react-admin';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    useRecordContext,
    Create,
    SimpleForm,
    TextInput,
    Edit,
    Pagination
} from 'react-admin';

import { Box } from '@mui/material';

const ListPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 200]} {...props} />;

const ExclusionTitle = () => {
    const record = useRecordContext();
    return <span>Liste des types de tâches</span>;
};


const ExclusionListActionToolbar = ({ children, ...props }) => (
    <Box sx={{ alignItems: 'center', display: 'flex' }}>{children}</Box>
);

export const ExclusionList = () => {
    return (
        <List 
            title={<ExclusionTitle />} 
            pagination={<ListPagination />}
        >
                <Datagrid
                    rowClick="edit"
                >
                    <TextField source="id" />
                    <TextField source="critere"label="Critère d'exclusion" />
                    <ExclusionListActionToolbar>
                        <EditButton />
                    </ExclusionListActionToolbar>
                </Datagrid>
        </List>
    );
}


export const ExclusionEdit = () => (
    <Edit title="Editer un type" >
      <SimpleForm>
        <TextInput disabled label="Id" source="id" />
        <TextInput source="critere" label="Critère d'exclusion" fullWidth />
      </SimpleForm>
    </Edit>
);


export const ExclusionCreate = props => (
    <Create title="Créer un type" {...props}>
      <SimpleForm>
        <TextInput source="critere" label="Critère d'exclusion" fullWidth />
      </SimpleForm>
    </Create>
);