// in src/posts.js
import * as React from "react";
//import { useMediaQuery } from '@mui/material';
//import { List, Datagrid, TextField, ReferenceField } from 'react-admin';
//import { List, Datagrid, TextField, ReferenceField, EditButton } from 'react-admin';
import {
    List,
    Datagrid,
    TextField,
    Filter,
    SelectInput,
    Pagination
} from 'react-admin';

// import { Box } from '@mui/material';

const ListPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 200]} {...props} />;

const LogTitle = () => {
    //const record = useRecordContext();
    return <span>Logs</span>;
};


// const LogListActionToolbar = ({ children, ...props }) => (
//     <Box sx={{ alignItems: 'center', display: 'flex' }}>{children}</Box>
// );

export const LogFilter = props => (
    <Filter {...props} >
        <SelectInput source="action" choices={[
            { id: 'UPDATE', name: 'UPDATE' },
            { id: 'CREATE', name: 'CREATE' },
            { id: 'DELETE', name: 'DELETE' },
        ]} alwaysOn />
        {/* <SelectInput source="contrat_actif" label="Statut contrat" choices={[
            { id: 'true', name: 'Contrats actifs' },
            { id: 'false', name: 'Contrats inactifs' }
        ]} alwaysOn /> */}
        {/* <ReferenceInput 
            label="Client"
            source="id_client" 
            reference="clients"
            sort={{ field: 'rs', order: 'ASC' }}
        >
            <AutocompleteInput
                label="Client"
                matchSuggestion={(
                    filterValue,
                    suggestion
                ) => {
                    const client = `${suggestion.rs}`;
                    return client.includes(filterValue);
                }}
                optionText={<OptionRendererClient />}
                filterToQuery='searchText => ({ q: [searchText] })'
                inputText={inputTextClient}
                fullWidth
            />
        </ReferenceInput> */}
    </Filter>
);

export const LogList = () => {
    return (
        <List 
            title={<LogTitle />} 
            filters={<LogFilter />}
            pagination={<ListPagination />}
            sort={{ field: 'id', order: 'DESC' }}
        >
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="createdAt" label="Date" />
                    <TextField source="user" label="Utilisateur" />
                    <TextField source="action" label="Action" />
                    <TextField source="ressource"label="Ressource" />
                    <TextField source="payload"label="Data" />
                </Datagrid>
        </List>
    );
}