// in src/posts.js
import React from 'react';
//import { useMediaQuery } from '@mui/material';
//import { List, Datagrid, TextField, ReferenceField } from 'react-admin';
//import { List, Datagrid, TextField, ReferenceField, EditButton } from 'react-admin';

import { useParams } from "react-router-dom"
//import TachesList from './tachesList';

import {
    Dialog,
    DialogContent,
    TextField as MuiTextField,
    DialogActions,
    Button,
} from '@mui/material';

import { Card, CardHeader, CardContent } from '@mui/material';

import {
    CreateButton,
    EditContextProvider,
    useEditController,
    Link as RaLink,
    SimpleForm,
    Title,
    minLength,
    ShowButton,
    TopToolbar,
    useCreateSuggestionContext,
    useCreate,
    useCreatePath,
    useEditContext,
    useListContext,
    EditContext,
    RecordContext,
    useDataProvider,
    useShowContext,
    useGetRecordId,
    useRecordContext,
    Filter,
    SelectInput,
    List,
    Show,
    SimpleShowLayout,
    Create,
    TabbedForm,
    FormTab,
    Form,
    Edit,
    Datagrid,
    TextField,
    DateField,
    TextInput,
    ReferenceField,
    ReferenceManyField,
    NumberInput,    
    DateInput,
    BooleanInput,
    EditButton,
    ReferenceInput,
    ReferenceOneField,
    ArrayInput,
    AutocompleteInput,
    SimpleFormIterator,
    BooleanField,
    ReferenceArrayInput,
    useGetOne,
    RichTextField,
    FilterButton,
    ExportButton,
    Toolbar,
    SaveButton,
    SelectArrayInput,
    useListController,
    ListContextProvider,
    useList,
    CloneButton,
    Pagination,
    FilterValues,
} from 'react-admin';

import { Count } from 'react-admin';

import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';

// import { Identifier } from 'react-admin';

// import ManageHistoryIcon from '@mui/icons-material/ManageHistory';

import { Typography,Box } from '@mui/material';

// import { RichTextInput } from 'ra-input-rich-text';

// import { Grid } from '@mui/material';

import Cookies from '../helpers/Cookies'

const niveau = Cookies.getCookie('niveau')

const TacheTitle = () => {
    const record = useRecordContext();
    return <span>Liste des tâches</span>;
};

const defaultSort = { field: 'rs', order: 'ASC' };

const ListPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 200]} {...props} />;

const exporter = evenements => {
    const postsForExport = evenements.map(evenement => {
        const { body_txt, body_html, ...postForExport } = evenement; // omit backlinks and author
        return postForExport;
    });
    jsonExport(postsForExport, {
        headers: [] // order fields in the export
    }, (err, csv) => {
        downloadCSV(csv, 'evenements'); // download as 'posts.csv` file
    });
};

/*
 * 
 /////////////////////////////////////////////////////////////////////
 ///////////////////////////////////////////////////// SHOW //////////
 */

/*

                <SimpleShowLayout>
                    <TextField source="id" />
                    <TextField source="etat" />
                    <TextField source="updatedAt" />

                    <ReferenceOneField label="Client" reference="clients" target="id_client">
                        <TextField source="rs" />
                    </ReferenceOneField>
                    
                    <ReferenceOneField label="Type" reference="types" target="id_type">
                        <TextField source="type" />
                    </ReferenceOneField>

                    <ReferenceOneField label="Lieu" reference="lieux" target="id_lieu">
                        <TextField source="lieu" />
                    </ReferenceOneField>
                    
                    <EditButton />
                </SimpleShowLayout>


*/

const ListActions = () => (
    <TopToolbar>
        {/* Add your custom actions */}
        <ExportButton/>
    </TopToolbar>
);

const showTacheFilter = [
    <DateInput source="createdAt_gte" alwaysOn label="Après" />,
    <DateInput source="createdAt_lte" alwaysOn label="Avant" />,
];

export const TacheShow = () => {
    // Récupération des événements de la tâche
    // Extraction Id Tâche
    const my_url = window.location.href;
    const pos = my_url.indexOf("/taches/");
    //console.log(pos);
    const pos_fin = my_url.indexOf("/", pos+8);
    //console.log(pos_fin);
    if (pos_fin > 0){
        var id_tache = my_url.substring(pos+8, pos_fin)
    }else{
        var id_tache = my_url.substring(pos+8)
    }

    // Extraction date début
    const pos_date_gte = my_url.indexOf("createdAt_gte");
    if (pos_date_gte > 0){
        var createdAt_gte = my_url.substring(pos_date_gte+22, pos_date_gte+32)
    }else{
        var createdAt_gte = "1970-01-01"
    }
    console.log("Date gte : " + createdAt_gte);

    // Extraction date début
    const pos_date_lte = my_url.indexOf("createdAt_lte");
    if (pos_date_lte > 0){
        var createdAt_lte = my_url.substring(pos_date_lte+22, pos_date_lte+32)
    }else{
        var createdAt_lte = "3000-01-01"
    }
    console.log("Date lte : " + createdAt_lte);

    console.log("ID Tâche : " + id_tache);

    const {
        defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
        error,  // error returned by dataProvider when it failed to fetch the record. Useful if you want to adapt the view instead of just showing a notification using the `onError` side effect.
        isFetching, // boolean that is true while the record is being fetched, and false once the record is fetched
        isLoading, // boolean that is true until the record is available for the first time
        record, // record fetched via dataProvider.getOne() based on the id from the location
        refetch, // callback to refetch the record via dataProvider.getOne()
        //resource, // the resource name, deduced from the location. e.g. 'posts'
    } = useShowContext();

    console.log("ID Tâche : " + record);

    const tachesFilters = [
        <TextInput source="id_tache" defaultValue="26" />,
    ];

    // const { total, data, resource } = useShowContext();

    useList("eventsTache");

    // https://mailvisionapi.stbackup.fr/api/evenements?sort=id:ASC&filters[id_tache]=26&pagination[page]=1&pagination[pageSize]=25
    // &filters[id_tache]=26

    // const { total, data, resource } = useListController();

// resource={resource
    const reloadPage = () => {
        window.location.reload();
    };

    return (
        <Show 
        
        >
            <SimpleShowLayout>
                <ReferenceField label="Client" reference="clients" source="id_client">
                    <TextField source="rs" />
                </ReferenceField>
                <ReferenceField label="Type de tâche surveillé" reference="types" source="id_type">
                    <TextField source="type" />
                </ReferenceField>
                <TextField label="Dernier état" source="etat" />
            </SimpleShowLayout>

            <SimpleShowLayout>
                <h2>Liste des événements associés à la tâche :</h2>

                

                <List
                    resource="evenements"
                    filters={showTacheFilter}
                    sort={{ field: 'id', order: 'DESC' }}
                    actions={<ListActions/>}
                    perPage={25}
                    storeKey="eventsTache"
                    filter={{ id_tache: {id_tache} }}
                    pagination={<ListPagination />}
                    exporter={exporter}
                >

                    <CardContent>
                        <p></p>
                        <Button variant="contained" color="primary" onClick={reloadPage}>
                            Faire le décompte
                        </Button>

                        <h4>Décompte sur la période : {createdAt_gte} - {createdAt_lte}</h4>
                        

                        <h4>SUCCES : <Count filter={{ id_tache: {id_tache}, etat: "SUCCES", createdAt_gte: createdAt_gte, createdAt_lte: createdAt_lte }} /></h4>
                        <h4>ECHEC : <Count filter={{ id_tache: {id_tache}, etat: "ECHEC", createdAt_gte: createdAt_gte, createdAt_lte: createdAt_lte }} /></h4>
                        <h4>NON RECEPTION : <Count filter={{ id_tache: {id_tache}, etat: "NON RECEPTION", createdAt_gte: createdAt_gte, createdAt_lte: createdAt_lte }} /></h4>
                        <h4>A SURVEILLER : <Count filter={{ id_tache: {id_tache}, etat: "A SURVEILLER", createdAt_gte: createdAt_gte, createdAt_lte: createdAt_lte }} /></h4>
                        <h4>TOTAL : <Count filter={{ id_tache: {id_tache}, createdAt_gte: createdAt_gte, createdAt_lte: createdAt_lte }} /></h4>
                    </CardContent>

                    <Datagrid
                        optimized
                        rowStyle={rowStyle}
                    >
                        <TextField source="id" />
                        {/* <TextField source="id_tache" /> */}
                        <TextField source="createdAt" label="Date" />
                        {/* <TextField source="date" /> */}
                        <TextField source="etat" />
                        <TextField source="from" />
                        <TextField source="subject" />
                    </Datagrid>
                </List>

            </SimpleShowLayout>
            
        </Show>
    );
}



const PostListActionToolbar = ({ children, ...props }) => (
    <Box sx={{ alignItems: 'center', display: 'flex' }}>{children}</Box>
);

/*
 * 
 /////////////////////////////////////////////////////////////////////
 ///////////////////////////////////////////////////// LIST //////////
 */
const TachePanel = () => {
    const record = useRecordContext();
    return (
        <SimpleShowLayout>
            <ReferenceField 
                        label="Client email from"
                        source="id_client" 
                        reference="clients"
            >
                <TextField source="email_from" />
            </ReferenceField>
            <hr />
            <ReferenceField 
                        label="Critère(s) type dans l'objet"
                        source="id_type" 
                        reference="types"
                    >
                <TextField source="mtype" />
            </ReferenceField>
            <ReferenceField 
                        label="Critère(s) type dans le contenu"
                        source="id_type" 
                        reference="types"
                    >
                <TextField source="mcontenu" />
            </ReferenceField>
            <hr />
            <TextField label="Critère(s) objet échec" source="coechec" />
            <TextField label="Critère(s) contenu échec" source="ccechec" />
            <hr />
            <TextField label="Critère(s) objet succes" source="cosucces" />
            <TextField label="Critère(s) contenu succes" source="ccsucces" />
        </SimpleShowLayout>
    );
};

const rowStyle = (record) => {
    let style = {};
    if (!record) {
        return style;
    }
    if (record.etat === 'SUCCES')
        return {
            ...style,
            borderLeftColor: "#4caf50",
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
            backgroundColor: "#4caf5040",
        };
    if (record.etat === 'A SURVEILLER')
        return {
            ...style,
            borderLeftColor: "#ff9800",
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
            backgroundColor: "#ff980040",
        };
    if (record.etat === 'ECHEC')
        return {
            ...style,
            borderLeftColor: "#f44336",
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
            backgroundColor: "#f4433640",
        };
    if (record.etat === 'NON RECEPTION')
        return {
            ...style,
            borderLeftColor: "#a7a19b",
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
            backgroundColor: "#a7a19b40",
        };
    return style;
};

export const TacheFilter = props => (
    <Filter {...props} >
        <SelectInput source="etat" choices={[
            { id: 'SUCCES', name: 'SUCCES' },
            { id: 'ECHEC', name: 'ECHEC' },
            { id: 'A SURVEILLER', name: 'A SURVEILLER' },
            { id: 'NON RECEPTION', name: 'NON RECEPTION' },
        ]} alwaysOn />
        <Typography variant="h6">ou</Typography>
        <SelectInput source="contrat_actif" label="Statut contrat" choices={[
            { id: 'true', name: 'Contrats actifs' },
            { id: 'false', name: 'Contrats inactifs' }
        ]} alwaysOn />
        {/* <ReferenceInput 
            label="Client"
            source="id_client" 
            reference="clients"
            sort={{ field: 'rs', order: 'ASC' }}
        >
            <AutocompleteInput
                label="Client"
                matchSuggestion={(
                    filterValue,
                    suggestion
                ) => {
                    const client = `${suggestion.rs}`;
                    return client.includes(filterValue);
                }}
                optionText={<OptionRendererClient />}
                filterToQuery='searchText => ({ q: [searchText] })'
                inputText={inputTextClient}
                fullWidth
            />
        </ReferenceInput> */}
    </Filter>
);

export const TacheList = () => {

    const ListActions = () => (
        <TopToolbar>
            <ExportButton />
        </TopToolbar>
    );
    
    const tacheRowStyle = (record, index) => ({
        backgroundColor: record.etat == "SUCCES" ? '#efe' : 'white'
    });
    // rowStyle={tacheRowStyle}

    // // filter={{ contrat_actif: true }}

    return (
        <List 
            title={<TacheTitle />} 
            filters={<TacheFilter />}
            filterDefaultValues={{ contrat_actif: true }}
            perPage={25}
            actions={<ListActions/>}
            pagination={<ListPagination />}
        >
                
                <Datagrid
                    //rowClick={(id: RA.Identifier, id_evenement: Datagrid.id_evenement) => `/taches/${id}?id_evenement=${id_evenement}`}
                    //rowClick="edit"
                    rowStyle={rowStyle}
                    rowClick="expand"
                    size="small"
                    expand={<TachePanel />}
                >
                    <TextField source="id" />
                    <TextField source="updatedAt" label="Dernière modification" />
                    

                    <ReferenceField 
                        label="Client"
                        source="id_client" 
                        reference="clients"
                    >
                        <TextField source="rs" />
                    </ReferenceField>

                    <ReferenceField 
                        label="Label type"
                        source="id_type" 
                        reference="types"
                    >
                        <TextField source="label" />
                    </ReferenceField>

                    <ReferenceField 
                        label="Job"
                        source="id_job" 
                        reference="jobs"
                    >
                        <TextField source="job" />
                    </ReferenceField>
                    
                    
                    <BooleanField
                        label="Contrat actif"
                        source="contrat_actif"
                        sx={{ mt: -0.5, mb: -0.5 }}
                    />

                    {/* <BooleanField
                        label="Modèle"
                        source="modele"
                        sx={{ mt: -0.5, mb: -0.5 }}
                    /> */}

                    <TextField source="etat" />
                    {
                    niveau === 'Admin' && 
                    <EditButton label="" />
                    }
                    {
                    niveau === 'Admin' && 
                    <CloneButton label="" />
                    }
                    <ShowButton label="" />
                </Datagrid>
        </List>
    );
}

/////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// EDIT //////////

/*
 * 
 ////////////////////////// ASIDE //////////
<Button color="primary" icon={ManageHistoryIcon}>Scanner les événements A CLASSER avec cette tâche</Button>
 */

// { evenement }
const Aside = ({id_evenement, evenement}) => {
    console.log("Aside ID Evenement lu " + id_evenement);

    //const { record, isLoading } = useEditContext();

    if (evenement == null){
        
    }else{
        return (
            <Box sx={{ width: '700px', margin: '1em' }}>
                <Typography variant="h6">Mail de référence { evenement.id }</Typography>
                <hr />
                <Typography variant="h7"><strong>From :</strong></Typography>
                <Typography variant="body2">
                { evenement.from }
                </Typography>
                <p></p>
                <Typography variant="h7"><strong>To :</strong></Typography>
                <Typography variant="body2">
                { evenement.to }
                </Typography>
                <p></p>
                <Typography variant="h7"><strong>Objet :</strong></Typography>
                <Typography variant="body2">
                { evenement.subject }
                </Typography>
                <p></p>
                <Typography variant="h7"><strong>Contenu texte brut :</strong></Typography>
                <Typography variant="body2">
                { evenement.body_txt.replace(/(\r\n|\r|\n)/g, '') }
                </Typography>
                <p></p>
                <Typography variant="h7"><strong>Contenu html :</strong></Typography>
                <div dangerouslySetInnerHTML={{
                __html: evenement.body_html
                }}></div>
            </Box>
            );
    }
};


/*
 * 
<Button color="primary" icon={ManageHistoryIcon}>Scanner les événements A CLASSER avec cette tâche</Button>
 */

 const TacheEditActions = () => (
    <TopToolbar>
        <CloneButton />
        {/* Add your custom actions */}
        
    </TopToolbar>
);

 export const TacheEdit = () => {
    console.log("EDIT -->>")

    const TacheEditToolbar = props => (
        <Toolbar {...props} >
            <SaveButton />
        </Toolbar>
    );

    // Extraction Id Tâche
    const my_url = window.location.href;
    const pos = my_url.indexOf("/taches/");
    //console.log(pos);
    const pos_fin = my_url.indexOf("/", pos+8);
    //console.log(pos_fin);
    if (pos_fin > 0){
        var id_tache = my_url.substring(pos+8, pos_fin)
    }else{
        var id_tache = my_url.substring(pos+8)
    }
    
    console.log("ID Tâche : " + id_tache);

    //console.log("ID Tâche " + record.id)
    const { data: tache, isLoading1, error1 } = useGetOne('taches', { id: id_tache });
    console.log("ID Evenement " + tache.id_evenement);

    var id_evenement = tache.id_evenement

    // Récupération de l'événement
    const { data: evenement, isLoading, error } = useGetOne('evenements', { id: id_evenement });
    
    // aside={<Aside evenement={ evenement } />}

    // Récupération de l'événement
    //const { data: evenement, isLoading, error } = useGetOne('evenements', { id: id_evenement });

    return (
        
        <Edit 
            title="Modification d'une tâche"
            actions={<TacheEditActions />}
            redirect="edit"
            aside={<Aside id_evenement={ id_evenement } evenement={ evenement } />}
        >
            <TabbedForm
                toolbar={<TacheEditToolbar />}
            >

                <FormTab label="1 - Client / Contrat">

                    <ReferenceInput 
                        source="id_client" 
                        reference="clients"
                        sort={{ field: 'rs', order: 'ASC' }}
                        perPage={500}
                    >
                        <AutocompleteInput
                            label="RS"
                            create={<CreateClient />}
                            matchSuggestion={(
                                filterValue,
                                suggestion
                            ) => {
                                const client = `${suggestion.rs}`;
                                return client.includes(filterValue);
                            }}
                            optionText={<OptionRendererClient />}
                            inputText={inputTextClient}
                            filterToQuery='searchText => ({ q: [searchText] })'
                            fullWidth
                        />
                    </ReferenceInput>



                    <BooleanInput 
                        source="contrat_actif" 
                        defaultValue 
                    />

                    <BooleanInput  
                        label="Identifier cette tâche comme modèle" 
                        source="modele" 
                    />

                    <TextInput
                        className=""
                        source="id_evenement"
                        label="Id événement mail de référence"
                    />

                </FormTab>


                <FormTab label="2 - Type">

                    <ReferenceInput 
                        source="id_type" 
                        reference="types"
                        sort={{ field: 'type', order: 'ASC' }}
                        perPage={500}
                    >
                        <AutocompleteInput
                            label="Label Type"
                            create={<CreateType />}
                            matchSuggestion={(
                                filterValue,
                                suggestion
                            ) => {
                                const label = `${suggestion.label}`;
                                return label.includes(filterValue);
                            }}
                            optionText={<OptionRendererType />}
                            inputText={inputTextType}
                            filterToQuery='searchText => ({ q: [searchText] })'
                            fullWidth
                        />
                    </ReferenceInput>

                </FormTab>

                <FormTab label="3 - Job">

                    <ReferenceInput 
                        source="id_job" 
                        reference="jobs"
                        sort={{ field: 'job', order: 'ASC' }}
                        perPage={500}
                    >
                        <AutocompleteInput
                            label="Critère Job"
                            create={<CreateJob />}
                            matchSuggestion={(
                                filterValue,
                                suggestion
                            ) => {
                                const label = `${suggestion.label}`;
                                return label.includes(filterValue);
                            }}
                            optionText={<OptionRendererJob />}
                            inputText={inputTextJob}
                            filterToQuery='searchText => ({ q: [searchText] })'
                            fullWidth
                        />
                    </ReferenceInput>

                </FormTab>
                

                <FormTab label="4 - Programme">

                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1}>
                            <BooleanInput source="lun" defaultValue />
                        </Box>
                        <Box flex={1}>
                            <BooleanInput source="mar" defaultValue />
                        </Box>
                        <Box flex={1}>
                            <BooleanInput source="mer" defaultValue />
                        </Box>
                        <Box flex={1}>
                            <BooleanInput source="jeu" defaultValue />
                        </Box>
                        <Box flex={1}>
                            <BooleanInput source="ven" defaultValue />
                        </Box>
                        <Box flex={1}>
                            <BooleanInput source="sam" defaultValue />
                        </Box>
                        <Box flex={1}>
                            <BooleanInput source="dim" defaultValue />
                        </Box>
                    </Box>
                    <TextInput label="Période (heure)" source="periode" />

                    
                </FormTab>

                <FormTab label="5 - Critère(s) ECHEC">

                    <Typography variant="h6" gutterBottom>
                        Critère(s) dans l'objet du mail
                    </Typography>

                    <ArrayInput 
                        label="Critère(s) échec objet" 
                        source="coechec"
                        defaultValue={[{}]}
                    >
                        <SimpleFormIterator>
                            <TextInput label="Critère" source="critere" />
                            <Typography variant="h7" gutterBottom>
                                <strong>ou</strong>
                            </Typography>
                        </SimpleFormIterator>
                    </ArrayInput>

                    <Typography variant="h6" gutterBottom>
                        <strong>ET</strong> dans le contenu du mail
                    </Typography>

                    <ArrayInput 
                        label="Critère(s) échec contenu" 
                        source="ccechec"
                    >
                        <SimpleFormIterator>
                            <TextInput label="Critère" source="critere" />
                            <Typography variant="h7" gutterBottom>
                                <strong>ou</strong>
                            </Typography>
                        </SimpleFormIterator>
                    </ArrayInput>
                    
                </FormTab>

                <FormTab label="6 - Critère(s) SUCCES">

                    <Typography variant="h6" gutterBottom>
                        Critère(s) dans l'objet du mail
                    </Typography>
                    <ArrayInput 
                        label="Critère(s) succes objet" 
                        source="cosucces"
                    >
                        <SimpleFormIterator>
                            <TextInput label="Critère" source="critere" />
                            <Typography variant="h7" gutterBottom>
                                <strong>ou</strong>
                            </Typography>
                        </SimpleFormIterator>
                    </ArrayInput>

                    <Typography variant="h6" gutterBottom>
                        <strong>ET</strong> dans le contenu du mail
                    </Typography>
                    <ArrayInput 
                        label="Critère(s) succes contenu" 
                        source="ccsucces"
                    >
                        <SimpleFormIterator>
                            <TextInput label="Critère 4" source="critere" />
                            <Typography variant="h7" gutterBottom>
                                <strong>ou</strong>
                            </Typography>
                        </SimpleFormIterator>
                    </ArrayInput>

                    
                </FormTab>

                
                
            </TabbedForm>

        </Edit>
    );
}


/*
 * 
 ///////////////////////////////////////////////////// CREATE //////////

 */


/***********************************

CLIENT

************************************/

// ({record?.id})
const OptionRendererClient = (props: any) => {
    const record = useRecordContext();
    return record.id === '@@ra-create' ? (
        <div {...props}>{record.name}</div>
    ) : (
        <div {...props}>
            {record?.rs}
        </div>
    );
};

// - ${record.id}
const inputTextClient = record =>
    record.id === '@@ra-create'
        ? record.name
        : `${record.rs}`;


const CreateClient = () => {
    const { filter, onCancel, onCreate } = useCreateSuggestionContext();
    const [value, setValue] = React.useState(filter || '');
    const [create] = useCreate();
    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        create(
            'clients',
            {
                data: {
                    rs: value,
                },
            },
            {
                onSuccess: data => {
                    setValue('');
                    const choice = data;
                    onCreate(choice);
                },
            }
        );
        return false;
    };
    return (
        <Dialog open onClose={onCancel}>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <MuiTextField
                        label="Nouveau client"
                        value={value}
                        onChange={event => setValue(event.target.value)}
                        autoFocus
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="submit">Créer</Button>
                    <Button onClick={onCancel}>Annuler</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

/***********************************

TYPE

************************************/

const OptionRendererType = (props: any) => {
    const record = useRecordContext();
    return record.id === '@@ra-create' ? (
        <div {...props}>{record.name}</div>
    ) : (
        <div {...props}>
            {record?.label} ({JSON.stringify(record?.mtype)} - {JSON.stringify(record?.mcontenu)})
        </div>
    );
};

const inputTextType = record =>
    record.id === '@@ra-create'
        ? record.name
        : `${record.label}`;


const CreateType = () => {
    const { filter, onCancel, onCreate } = useCreateSuggestionContext();
    const [value, setValue] = React.useState(filter || '');
    const [create] = useCreate();
    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        create(
            'types',
            {
                data: {
                    label: value,
                },
            },
            {
                onSuccess: data => {
                    setValue('');
                    const choice = data;
                    onCreate(choice);
                },
            }
        );
        return false;
    };
    return (
        <Dialog open onClose={onCancel}>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <MuiTextField
                        label="Nouveau type"
                        value={value}
                        onChange={event => setValue(event.target.value)}
                        autoFocus
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="submit">Créer</Button>
                    <Button onClick={onCancel}>Annuler</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

/***********************************

Job

************************************/


const OptionRendererJob = (props: any) => {
    const record = useRecordContext();
    return record.id === '@@ra-create' ? (
        <div {...props}>{record.name}</div>
    ) : (
        <div {...props}>
            {record?.job}
        </div>
    );
};

const inputTextJob = record =>
    record.id === '@@ra-create'
        ? record.name
        : `${record.job}`;


const CreateJob = () => {
    const { filter, onCancel, onCreate } = useCreateSuggestionContext();
    const [value, setValue] = React.useState(filter || '');
    const [create] = useCreate();
    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        create(
            'jobs',
            {
                data: {
                    job: value,
                },
            },
            {
                onSuccess: data => {
                    setValue('');
                    const choice = data;
                    onCreate(choice);
                },
            }
        );
        return false;
    };
    return (
        <Dialog open onClose={onCancel}>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <MuiTextField
                        label="Nouveau job"
                        value={value}
                        onChange={event => setValue(event.target.value)}
                        autoFocus
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="submit">Créer</Button>
                    <Button onClick={onCancel}>Annuler</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};


/***********************************

LIEU

************************************/

/*

const OptionRendererLieu = (props: any) => {
    const record = useRecordContext();
    return record.id === '@@ra-create' ? (
        <div {...props}>{record.name}</div>
    ) : (
        <div {...props}>
            {record?.lieu}
        </div>
    );
};

const inputTextLieu = record =>
    record.id === '@@ra-create'
        ? record.name
        : `${record.lieu}`;


const CreateLieu = () => {
    const { filter, onCancel, onCreate } = useCreateSuggestionContext();
    const [value, setValue] = React.useState(filter || '');
    const [create] = useCreate();
    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        create(
            'lieux',
            {
                data: {
                    lieu: value,
                },
            },
            {
                onSuccess: data => {
                    setValue('');
                    const choice = data;
                    onCreate(choice);
                },
            }
        );
        return false;
    };
    return (
        <Dialog open onClose={onCancel}>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <MuiTextField
                        label="Nouveau lieu"
                        value={value}
                        onChange={event => setValue(event.target.value)}
                        autoFocus
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="submit">Créer</Button>
                    <Button onClick={onCancel}>Annuler</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};


*/

/***********************************

CREATE

************************************/

/*

<FormTab label="4 - Lieu">

    <ReferenceInput 
        source="id_lieu" 
        reference="lieux"
        sort={{ field: 'lieu', order: 'ASC' }}
    >
        <AutocompleteInput
            label="Lieu"
            create={<CreateLieu />}
            matchSuggestion={(
                filterValue,
                suggestion
            ) => {
                const lieu = `${suggestion.lieu}`;
                return lieu.includes(filterValue);
            }}
            optionText={<OptionRendererLieu />}
            inputText={inputTextLieu}
            filterToQuery='searchText => ({ q: [searchText] })'
            fullWidth
        />
    </ReferenceInput>

</FormTab>

*/


export const TacheCreate = () => {

    // Extraction id_evenement des l'URL
    const my_url = window.location.href;
    const pos = my_url.indexOf("?");
    const queryParams = new URLSearchParams(my_url.substring(pos));
    const id_evenement = queryParams.get("id_evenement");
    //id_evenement = queryParams.get("id_evenement");

    console.log("EVENT " + id_evenement);

    // Récupération de l'événement
    const { data: evenement, isLoading, error } = useGetOne('evenements', { id: id_evenement });
    
    // aside={<Aside evenement={ evenement } />}
    return (
        <Create 
            title="Wizard de création d'une tâche"
            aside={<Aside id_evenement={ id_evenement }  evenement={ evenement } />}
        >
            <TabbedForm>

                <FormTab label="1 - Client / Contrat">

                    <ReferenceInput 
                        source="id_client" 
                        reference="clients"
                        perPage="500"
                        sort={{ field: 'rs', order: 'ASC' }}
                    >
                        <AutocompleteInput
                            label="RS"
                            create={<CreateClient />}
                            matchSuggestion={(
                                filterValue,
                                suggestion
                            ) => {
                                const client = `${suggestion.rs}`;
                                return client.includes(filterValue);
                            }}
                            optionText={<OptionRendererClient />}
                            inputText={inputTextClient}
                            filterToQuery='searchText => ({ q: [searchText] })'
                            fullWidth
                        />
                    </ReferenceInput>

                    <BooleanInput 
                        source="contrat_actif" 
                        defaultValue 
                    />

                    <TextInput
                        className=""
                        source="id_evenement"
                        label="Id événement mail de référence"
                        defaultValue={ id_evenement }
                    />

                </FormTab>


                <FormTab label="2 - Type">

                    <ReferenceInput 
                        source="id_type" 
                        reference="types"
                        perPage="500"
                        sort={{ field: 'label', order: 'ASC' }}
                    >
                        <AutocompleteInput
                            label="Label Type"
                            create={<CreateType />}
                            matchSuggestion={(
                                filterValue,
                                suggestion
                            ) => {
                                const label = `${suggestion.label}`;
                                return label.includes(filterValue);
                            }}
                            optionText={<OptionRendererType />}
                            inputText={inputTextType}
                            filterToQuery='searchText => ({ q: [searchText] })'
                            fullWidth
                        />
                    </ReferenceInput>

                </FormTab>

                <FormTab label="3 - Job">

                    <ReferenceInput 
                        source="id_job" 
                        reference="jobs"
                        sort={{ field: 'job', order: 'ASC' }}
                        perPage={500}
                    >
                        <AutocompleteInput
                            label="Critère Job"
                            create={<CreateJob />}
                            matchSuggestion={(
                                filterValue,
                                suggestion
                            ) => {
                                const label = `${suggestion.label}`;
                                return label.includes(filterValue);
                            }}
                            optionText={<OptionRendererJob />}
                            inputText={inputTextJob}
                            filterToQuery='searchText => ({ q: [searchText] })'
                            fullWidth
                        />
                    </ReferenceInput>

                </FormTab>
                

                <FormTab label="4 - Programme">

                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1}>
                            <BooleanInput source="lun" defaultValue />
                        </Box>
                        <Box flex={1}>
                            <BooleanInput source="mar" defaultValue />
                        </Box>
                        <Box flex={1}>
                            <BooleanInput source="mer" defaultValue />
                        </Box>
                        <Box flex={1}>
                            <BooleanInput source="jeu" defaultValue />
                        </Box>
                        <Box flex={1}>
                            <BooleanInput source="ven" defaultValue />
                        </Box>
                        <Box flex={1}>
                            <BooleanInput source="sam" defaultValue />
                        </Box>
                        <Box flex={1}>
                            <BooleanInput source="dim" defaultValue />
                        </Box>
                    </Box>
                    <TextInput 
                        label="Période (heure)" 
                        source="periode"
                        defaultValue="24"
                    />
                    
                </FormTab>

                <FormTab label="5 - Critère(s) ECHEC">

                    <Typography variant="h6" gutterBottom>
                        Dans l'objet du mail
                    </Typography>

                    <ArrayInput 
                        label="Critère(s) échec objet" 
                        source="coechec"
                    >
                        <SimpleFormIterator>
                            <TextInput label="Critère" source="critere" />
                        </SimpleFormIterator>
                    </ArrayInput>

                    <Typography variant="h6" gutterBottom>
                    Dans le contenu du mail
                    </Typography>

                    <ArrayInput 
                        label="Critère(s) échec contenu" 
                        source="ccechec"
                    >
                        <SimpleFormIterator>
                            <TextInput label="Critère" source="critere" />
                        </SimpleFormIterator>
                    </ArrayInput>
                    
                </FormTab>

                <FormTab label="6 - Critère(s) SUCCES">

                    <Typography variant="h6" gutterBottom>
                        Dans l'objet du mail
                    </Typography>
                    <ArrayInput 
                        label="Critère(s) succes objet" 
                        source="cosucces"
                    >
                        <SimpleFormIterator>
                            <TextInput label="Critère" source="critere" />
                        </SimpleFormIterator>
                    </ArrayInput>

                    <Typography variant="h6" gutterBottom>
                        Dans le contenu du mail
                    </Typography>
                    <ArrayInput 
                        label="Critère(s) succes contenu" 
                        source="ccsucces"
                    >
                        <SimpleFormIterator>
                            <TextInput label="Critère" source="critere" />
                        </SimpleFormIterator>
                    </ArrayInput>
                    
                </FormTab>
                
            </TabbedForm>
        </Create>
    );
}