// in src/posts.js
import * as React from "react";
//import { useMediaQuery } from '@mui/material';
//import { List, Datagrid, TextField, ReferenceField } from 'react-admin';
//import { List, Datagrid, TextField, ReferenceField, EditButton } from 'react-admin';
import {
    Filter,
    Datagrid,
    TextField,
    EditButton,
    ReferenceInput,
    SelectInput,
    TextInput,
    useRecordContext,
    Show,
    SimpleShowLayout,
    RichTextField,
    DateField,
    ShowButton,
    TabbedShowLayout,
    Tab,
    NumberField,
    BooleanField,
    ReferenceManyField,
    SaveButton,
    Form,
    Resource,
    Button,
    Link,
    TopToolbar,
    FilterButton,
    CreateButton,
    ExportButton,
    useGetOne,
    useGetMany,
    useGetManyReference,
    useGetList,
    Pagination,
    useDataProvider,
    SearchInput,
    AutocompleteInput,
    FilterForm,
    useAuthState
} from 'react-admin';

import { Stack } from '@mui/material';

import { List, downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';

// import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

// import UserIcon from '@mui/icons-material/Group';

// import { Grid } from '@mui/material';

// import { Chip } from '@mui/material'

import { TacheCreate } from '../taches/taches';

import { FilterLiveSearch, FilterList, FilterListItem } from 'react-admin';
import { Card, CardContent } from '@mui/material';
import MailIcon from '@mui/icons-material/MailOutline';
import CategoryIcon from '@mui/icons-material/LocalOffer';


import Cookies from '../helpers/Cookies'

const niveau = Cookies.getCookie('niveau')

const exporter = evenements => {
    const postsForExport = evenements.map(evenement => {
        const { body_txt, body_html, ...postForExport } = evenement; // omit backlinks and author
        return postForExport;
    });
    jsonExport(postsForExport, {
        headers: [] // order fields in the export
    }, (err, csv) => {
        downloadCSV(csv, 'evenements'); // download as 'posts.csv` file
    });
};


const ListPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 200]} {...props} />;

export const PostFilterSidebar = () => (
    <Card sx={{ order: -1, mr: 2, mt: 9, width: 250 }}>
        <CardContent>
            <FilterLiveSearch source="subject" />
            <FilterList label="Etat" icon={<MailIcon />}>
                <FilterListItem label="A CLASSER" value={{ etat: 'A CLASSER' }} />
                <FilterListItem label="SUCCES" value={{ etat: 'SUCCES' }} />
                <FilterListItem label="ECHEC" value={{ etat: 'ECHEC' }} />
                <FilterListItem label="A SURVEILLER" value={{ etat: 'A SURVEILLER' }} />
                <FilterListItem label="NON RECEPTION" value={{ etat: 'NON RECEPTION' }} />
            </FilterList>
            <FilterList label="Category" icon={<CategoryIcon />}>
                <FilterListItem label="Tests" value={{ category: 'tests' }} />
                <FilterListItem label="News" value={{ category: 'news' }} />
                <FilterListItem label="Deals" value={{ category: 'deals' }} />
                <FilterListItem label="Tutorials" value={{ category: 'tutorials' }} />
            </FilterList>
            <FilterList label="Subject" icon={<CategoryIcon />}>
                
            </FilterList>
            
        </CardContent>
    </Card>
);

export const EvenementFilter = props => (
    <Filter {...props} >
        <SearchInput source="subject" alwaysOn />
    </Filter>
);

const EvenementTitle = () => {
    return <span>Liste des événements "A CLASSER"</span>;
};

const EvenementTitleWizard = () => {
    return <span>Liste des événements "A CLASSER"</span>;
};


const ListEvenement = ({ record }) => {
    const { data, isLoading, error } = useGetMany(
        'evenements',
        { id: 151 }
    );
    if (isLoading) { return ""; }
    if (error) { return <p>ERROR</p>; }
    return (
         <ul>
             {data.map(tag => (
                 <li key={tag.id}>{tag.name}</li>
             ))}
         </ul>
     );
};

// 
/*

        <Button
            onClick={() => { MailScan(); }}
            label="Recheck événements"
        >
        <PublishedWithChangesIcon/>
        </Button>       

*/


const ListActions = () => (
    <TopToolbar>
        {/* Add your custom actions */}
        <ExportButton/>
    </TopToolbar>
);

//const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;


//////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////// LIST /////////////

const evenementFilters = [
    <TextInput label="Rech. dans Subject" source="subject" alwaysOn />,
];

///#/taches/create?id_evenement=457
//rowClick="show"
export const EvenementList = () => {
    // Recheck on reload
    console.log("Get liste dans Event");
    //const { data: evenement, isLoading, error } = useGetOne('evenements', { id: 157 });
    //const dataProvider = useDataProvider();

    // Test getOne
    /*
    dataProvider.getOne('evenements', { id: 76 })
    .then(({ data }) => {
        //     \- type of data is Product
        // ...
        console.log(data.etat);
    })
    */

    /*
    dataProvider.getList(
        'evenements',
        { 
            pagination: { page: 1, perPage: 25 }
        }
    )
    .then(({ data }) => {
        //     \- type of data is Product
        // ...
        {data.map(record =>
            console.log(record.etat + " " + record.from)
            // TODO Reclasser les événements
        )}
    });
    */

    //<TextField source="to" />
    //
    //filters={<EvenementFilter />}
    //pagination={<PostPagination />}
    //perPage={50}
    // aside={<PostFilterSidebar />}
    //filterDefaultValues={{ etat: "A CLASSER" }}
    // filter={{ etat: "A CLASSER" }}
    // <TextField source="etat" />

    return (
        <List 
            title='Liste des événements "A CLASSER"'
            perPage={25}
            filter={{ etat: "A CLASSER" }}
            filters={evenementFilters}
            actions={<ListActions/>}
            pagination={<ListPagination />}
            exporter={exporter}
        >
            <Datagrid
                rowClick={niveau === 'Admin' && ((id: RA.Identifier) => `/taches/create?id_evenement=${id}`)}
                optimized
            >
                <TextField source="id" />
                <TextField source="createdAt" label="Date" />
                {/* <TextField source="date" /> */}
                <TextField source="from" />
                <TextField source="subject" />
            </Datagrid>
        </List>
    );
}

//////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////// SHOW /////////////



export const EvenementShow  = () => {
    return (
        <Show 
            title="Wizard de création d'une tâche"
        >
            <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="etat" />
                <DateField source="date" />
                <TextField source="from" />
                <TextField source="to" />
                <TextField source="subject" />
                <RichTextField source="body_txt" />
                <RichTextField source="body_html" />
            </SimpleShowLayout>
            
            <hr />
            <hr />

            <TacheCreate 
                name="taches"
            />
            
        </Show>
        
  );
}