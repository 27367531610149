// in src/posts.js
import * as React from "react";
//import { useMediaQuery } from '@mui/material';
//import { List, Datagrid, TextField, ReferenceField } from 'react-admin';
//import { List, Datagrid, TextField, ReferenceField, EditButton } from 'react-admin';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    useRecordContext,
    Create,
    SimpleForm,
    TextInput,
    Edit,
    Pagination
} from 'react-admin';

import { Box } from '@mui/material';

const ListPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 200]} {...props} />;

const ClientTitle = () => {
    const record = useRecordContext();
    return <span>Liste des clients</span>;
};


const ClientListActionToolbar = ({ children, ...props }) => (
    <Box sx={{ alignItems: 'center', display: 'flex' }}>{children}</Box>
);

export const ClientList = () => {
    return (
        <List 
            title={<ClientTitle />} 
            pagination={<ListPagination />}
        >
                <Datagrid
                    rowClick="edit"
                >
                    <TextField source="id" />
                    <TextField source="rs" />
                    <TextField source="email_from" />
                    <ClientListActionToolbar>
                        <EditButton />
                    </ClientListActionToolbar>

                </Datagrid>
        </List>
    );
}

export const ClientEdit = () => (
    <Edit title="Editer un client" >
      <SimpleForm>
        <TextInput disabled label="Id" source="id" />
        <TextInput source="rs" label="Raison social" fullWidth />
        <TextInput source="email_from" label="Email from" fullWidth />
      </SimpleForm>
    </Edit>
);

export const ClientCreate = props => (
    <Create title="Créer un client" {...props}>
      <SimpleForm>
        <TextInput source="rs" label="Raison social" fullWidth />
        <TextInput source="email_from" label="Email from" fullWidth />
      </SimpleForm>
    </Create>
);