// in src/posts.js
import * as React from "react";
//import { useMediaQuery } from '@mui/material';
//import { List, Datagrid, TextField, ReferenceField } from 'react-admin';
//import { List, Datagrid, TextField, ReferenceField, EditButton } from 'react-admin';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    Create,
    SimpleForm,
    TextInput,
    Edit,
    Pagination
} from 'react-admin';

//import { Typography } from '@mui/material';

import { Box } from '@mui/material';

const ListPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 200]} {...props} />;

const JobTitle = () => {
    //const record = useRecordContext();
    return <span>Liste des Jobs de tâches</span>;
};


const JobListActionToolbar = ({ children, ...props }) => (
    <Box sx={{ alignItems: 'center', display: 'flex' }}>{children}</Box>
);

/*
 * 
 /////////////////////////////////////////////////////////////////////
 ///////////////////////////////////////////////////// LIST //////////
 */


export const JobList = () => {
    return (
        <List 
            title={<JobTitle />} 
            pagination={<ListPagination />}
        >
                <Datagrid
                    rowClick="edit"
                    size="small"
                >
                    <TextField source="id" />
                    <TextField source="job" label="Critère Job" />
                    <JobListActionToolbar>
                        <EditButton />
                    </JobListActionToolbar>
                </Datagrid>
        </List>
    );
}


/*
 * 
 /////////////////////////////////////////////////////////////////////
 ///////////////////////////////////////////////////// EDIT //////////
 */

export const JobEdit = () => (
    <Edit title="Editer un Job" >
      <SimpleForm>
        <TextInput disabled label="Id" source="id" />
        <TextInput source="job" label="Critère Job" fullWidth />
      </SimpleForm>
    </Edit>
);

/*
 * 
 /////////////////////////////////////////////////////////////////////
 ///////////////////////////////////////////////////// CREATE ////////
 */
export const JobCreate = props => (
    <Create title="Créer un Job" {...props}>
      <SimpleForm>
        <TextInput source="job" label="Critère Job" fullWidth />
      </SimpleForm>
    </Create>
);