import * as React from "react";
import { Admin, Resource, EditGuesser } from 'react-admin';
import i18nProvider from './i18nProvider';

import { EvenementList, EvenementShow } from './evenements/evenements';
import { TacheList, TacheShow,TacheCreate,TacheEdit } from './taches/taches';
import { TypeList, TypeCreate, TypeEdit } from './types/types';
import { JobList, JobCreate, JobEdit } from './jobs/jobs';
import { ExclusionList, ExclusionCreate, ExclusionEdit } from './exclusions/exclusions';
import { ClientList, ClientCreate, ClientEdit } from './clients/clients';
import { LogList } from './logs/logs';

// Icons
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import MarkAsUnreadOutlinedIcon from '@mui/icons-material/MarkAsUnreadOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';


import authProvider from './authProvider';
import simpleRestProvider from './ra-strapi-rest';
import Cookies from './helpers/Cookies'

//import { addEventsForMutations } from "@react-admin/ra-audit-log";

// Base de donnée
const dataProvider = simpleRestProvider('https://mailvisionapi.stbackup.fr/api');

const niveau = Cookies.getCookie('niveau')

const App = () => (
    <Admin 
        title="Mail vision"
        disableTelemetry
        i18nProvider={i18nProvider}
        authProvider={authProvider} 
        dataProvider={dataProvider}
        //dashboard={Dashboard} 
    >
        {Cookies.getCookie('niveau')}
        <Resource 
            name="evenements" 
            options={{ label: 'Événements' }}
            icon={MarkAsUnreadOutlinedIcon}
            show={EvenementShow}
            list={EvenementList}
            edit={niveau === 'Admin' && EditGuesser}
        />
        <Resource 
            name="taches" 
            options={{ label: 'Tâches' }}
            icon={ManageHistoryIcon}
            list={TacheList}
            show={TacheShow}
            create={niveau === 'Admin' && TacheCreate}
            edit={niveau === 'Admin' && TacheEdit}
        />
        {
        niveau === 'Admin' && 
            <Resource 
                name="types" 
                options={{ label: 'Types' }}
                icon={GridViewOutlinedIcon}
                list={TypeList} 
                edit={TypeEdit} 
                create={TypeCreate} 
            />
        }
        {
        niveau === 'Admin' && 
            <Resource 
                name="jobs" 
                options={{ label: 'Jobs' }}
                icon={LibraryBooksOutlinedIcon}
                list={JobList} 
                edit={JobEdit} 
                create={JobCreate} 
            />
        }
        {
        niveau === 'Admin' && 
            <Resource 
                name="exclusions" 
                icon={CallMissedOutgoingIcon}
                list={ExclusionList} 
                edit={ExclusionEdit} 
                create={ExclusionCreate}
            />
        }
        {
        niveau === 'Admin' && 
            <Resource 
                name="clients" 
                options={{ label: 'Clients' }}
                icon={PeopleAltOutlinedIcon}
                list={ClientList} 
                edit={ClientEdit} 
                create={ClientCreate}
            />
        }
        {
        niveau === 'Admin' && 
            <Resource 
                name="logs" 
                options={{ label: 'Logs' }}
                icon={FormatListBulletedOutlinedIcon}
                list={LogList} 
            />
        }
    </Admin>
);

export default App;