// in src/authProvider.js
import Cookies from './helpers/Cookies'

export default {

    login: ({ username, password }) => {
        const identifier = username // strapi expects 'identifier' and not 'username'
        const request = new Request('https://mailvisionapi.stbackup.fr/api/auth/local', {
            method: 'POST',
            body: JSON.stringify({ identifier, password }),
            headers: new Headers({ 'Content-Type': 'application/json'})
        });
        
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(response => {
                Cookies.setCookie('token', response.jwt, 1);
                Cookies.setCookie('niveau', response.user.niveau, 1);
                // Cookies.setCookie('role', response.user.username, 1);
                Cookies.setCookie('id', response.user.id, 1);
                Cookies.setCookie('username', response.user.username, 1);

                //Ra-admin
                localStorage.setItem('username', username);
            })
            .catch(() => {
                throw new Error("Erreur d'authentification !")
            });
    },

    logout: () => {
        Cookies.deleteCookie('token');
        Cookies.deleteCookie('role');
        // RA-Admin
        localStorage.removeItem('username');
        return Promise.resolve();
    },

    checkAuth: () => {
        return Cookies.getCookie('token') ? Promise.resolve() : Promise.reject();
    },

    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            Cookies.deleteCookie('token');
            Cookies.deleteCookie('niveau');
            return Promise.reject();
        }
        return Promise.resolve();
    },

    getIdentity: () => {
        try {
            const id = Cookies.getCookie('id');
            const username = Cookies.getCookie('username')
            return Promise.resolve({ 
                id: id, 
                fullName: username,
                avatar: "",
            });
        } catch (error) {
            return Promise.reject(error);
        }
    },

    getPermissions: () => {
        const role = Cookies.getCookie('role');
        return role ? Promise.resolve(role) : Promise.reject();
    },




}



// const authProvider = {
//     // called when the user attempts to log in
//     login: ({ username }) => {
//         localStorage.setItem('username', username);
//         // accept all username/password combinations
//         return Promise.resolve();
//     },
//     // called when the user clicks on the logout button
//     logout: () => {
//         localStorage.removeItem('username');
//         return Promise.resolve();
//     },
//     // called when the API returns an error
//     checkError: ({ status }) => {
//         if (status === 401 || status === 403) {
//             localStorage.removeItem('username');
//             return Promise.reject();
//         }
//         return Promise.resolve();
//     },
//     // called when the user navigates to a new location, to check for authentication
//     checkAuth: () => {
//         return localStorage.getItem('username')
//             ? Promise.resolve()
//             : Promise.reject();
//     },
//     // called when the user navigates to a new location, to check for permissions / roles
//     getPermissions: () => Promise.resolve(),
// };

// export default authProvider;